import { useEffect, useState } from "react";
import { signOut, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";

export function login(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // eslint-disable-next-line
      const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
    });
}

export function useAuth() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      user ? setUser(user) : setUser(null);
    });
  }, []);
  return user;
}

export function logout() {
  signOut(auth).then();
}
