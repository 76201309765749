import dashboardIcon from "../assets/dashboard.png";
import housekeepingIcon from "../assets/housekeeping.png";
import settingsIcon from "../assets/settings.png";
import logoutIcon from "../assets/logout.png";
import { logout } from "../services/authService";
import { user } from "../data/userData";

export default function ModuleMenu({ selectedModule, setModule, popup }) {
  return (
    <div className="module-menu">
      <div
        title="Dashboard"
        className="module-menu-item"
        onClick={() => setModule("")}
      >
        <img src={dashboardIcon} alt="D" width="20" height="20" />
      </div>
      {user.housekeeping && (
        <div
          title="Housekeeping"
          className="module-menu-item"
          onClick={() => setModule("housekeeping")}
        >
          <img src={housekeepingIcon} alt="H" width="20" height="20" />
        </div>
      )}
      {user.admin && (
        <div
          className="module-menu-item"
          title="Settings"
          onClick={() => setModule("settings")}
        >
          <img src={settingsIcon} alt="S" width="20" height="20" />
        </div>
      )}
      <div
        title="Logout"
        className="module-menu-item"
        onClick={() => {
          popup.setHeader("Logout?");
          popup.setBody("Are you sure you want to logout?");
          popup.setConfirm(() => logout);
          popup.setPopup(true);
          popup.setCancel(() => popup.setPopup);
        }}
      >
        <img src={logoutIcon} alt="L" width="20" height="20" />
      </div>
    </div>
  );
}
