import Admin from "./Admin";
import Dashboard from "./Dashboard";
import Housekeeping from "./Housekeeping";
import { sysMessages, orgMessages } from "../data/systemMessagesData";

export default function ModuleArea({ selectedModule }) {
  return (
    <div className="module-area">
      {selectedModule === "" && (
        <Dashboard sysMessages={sysMessages} orgMessages={orgMessages} />
      )}
      {selectedModule === "housekeeping" && <Housekeeping />}
      {selectedModule === "settings" && <Admin />}
    </div>
  );
}
