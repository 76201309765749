import { useState } from "react";
import { login } from "../services/authService";

export default function Login({ setUser }) {
  const [emailInput, setEmailInput] = useState("");
  const [pwInput, setPWinput] = useState("");

  function submit(e) {
    e.preventDefault();
  }

  return (
    <div className="columns-two">
      <form className="panel-centered" onSubmit={submit}>
        <h1 styles="text-align: center;">Login</h1>
        <input
          type="email"
          placeholder="Email"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={pwInput}
          onChange={(e) => setPWinput(e.target.value)}
        />
        <button
          className="button-primary"
          onClick={() => login(emailInput, pwInput, setUser)}
        >
          Login
        </button>
      </form>
      <h1>Info</h1>
    </div>
  );
}
