import { getMessages } from "../services/dataService";

export default function Dashboard() {
  const messages = getMessages();
  const sysMessages = messages.sysMessages;
  const orgMessages = messages.orgMessages;

  return (
    <div className="dashboard">
      <h4>Your Dashboard</h4>
      {sysMessages.length > 0 && (
        <div className="panel">
          <h5>System Messages</h5>
          {sysMessages.map((m) => (
            <div key={m.systemMessageId} className="text">
              {m.message}
            </div>
          ))}
        </div>
      )}
      <div className="panel">
        <h5>Organization Messages</h5>
        {orgMessages.length > 0 ? (
          orgMessages.map((m) => (
            <div key={m.systemMessageId} className="text">
              {m.message}
            </div>
          ))
        ) : (
          <div className="text">No Organization Messages</div>
        )}
      </div>
    </div>
  );
}
