export default function Popup({ header, body, confirm, cancel }) {
  return (
    <div className="popup-wrapper">
      <div className="popup-background" />
      <div className="popup">
        {header && <h1>{header}</h1>}
        {body && <div className="text">{body}</div>}
        <h4>
          {cancel && (
            <button className="button-secondary" onClick={() => cancel(false)}>
              Cancel
            </button>
          )}
          {confirm && (
            <button className="button-primary" onClick={() => confirm()}>
              Logout
            </button>
          )}
        </h4>
      </div>
    </div>
  );
}
