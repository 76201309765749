const message1 = {
  systemMessageId: 12829308302730283,
  orgCode: "LMU",
  isActive: true,
  startDate: "2023-03-26",
  endDate: "20203-03-30",
  message: "This is a test message.",
};

const message2 = {
  systemMessageId: 12829308302730283,
  orgCode: "LMU",
  isActive: true,
  startDate: "2023-03-26",
  endDate: "20203-03-30",
  message: "This is a second test message.",
};

export const sysMessages = [message2];
export const orgMessages = [message1];

export function getMessages() {
  return { sysMessages, orgMessages };
}
