export default function Housekeeping() {
  return (
    <div className="housekeeping">
      <h4>Housekeeping</h4>
      <div className="panel">
        <div className="panel-header">My First Panel</div>
      </div>
      <div className="panel">
        <div className="panel-header">My Second Panel</div>
      </div>
      <div className="panel">
        <div className="panel-header">My Third Panel</div>
      </div>
    </div>
  );
}
