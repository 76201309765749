export const user = {
  first: "Brandon",
  last: "Holmes",
  housekeeping: true,
  viewOne: true,
  viewTwo: true,
  viewThree: true,
  admin: true,
  orgCode: "LMU",
};
