import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBlyZ-ge-h2y9QMfnpDq0pThyjWSJDQ3g4",
  authDomain: "housingmobile-aae76.firebaseapp.com",
  projectId: "housingmobile-aae76",
  storageBucket: "housingmobile-aae76.appspot.com",
  messagingSenderId: "621910408621",
  appId: "1:621910408621:web:4eebc540e26ebbdd443524",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
