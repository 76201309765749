import Popup from "./components/Popup";
import { useEffect, useState } from "react";
import { useAuth } from "./services/authService";
import Header from "./components/Header";
import Login from "./components/Login";
import ModuleMenu from "./components/ModuleMenu";
import ModuleArea from "./components/ModuleArea";
import "./App.css";

function App() {
  const [selectedModule, setModule] = useState("");
  const [renderPopup, setPopup] = useState(false);
  const [popupHeader, setHeader] = useState("");
  const [popupBody, setBody] = useState("");
  const [popupConfirm, setConfirm] = useState(null);
  const [popupCancel, setCancel] = useState(null);
  const popup = { setPopup, setHeader, setBody, setConfirm, setCancel };
  const user = useAuth();

  useEffect(() => {
    if (!user) {
      setModule("");
      setPopup(false);
    }
  }, [user]);

  return (
    <div className="app">
      {renderPopup && (
        <Popup
          header={popupHeader}
          body={popupBody}
          confirm={popupConfirm}
          cancel={popupCancel}
        />
      )}
      <Header />
      {user ? (
        <div className="body">
          <ModuleMenu
            selectedModule={selectedModule}
            setModule={setModule}
            popup={popup}
          />
          <ModuleArea selectedModule={selectedModule} />
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
}

export default App;
